<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>用户管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>用户列表</span>
				</div>
				<div class="left-list-item" @click="leftClick('1')">
					<span>用户权限</span>
				</div>
			</div>
			<div class="right-next">
				<div class="title">
					<span>{{title}}</span>
				</div>
				<div class="add-from">
					<div class="item">
						<div class="label bit">公司名称：</div>
						<el-select v-model="userinfo.corporateName" clearable placeholder="请选择公司">
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="item">
						<div class="label bit">昵称：</div>
						<el-input v-model="userinfo.nickname" placeholder="请输入昵称"></el-input>
					</div>
					<div class="item">
						<div class="label bit">所属部门：</div>
						<el-input v-model="userinfo.department" placeholder="请输入所属部门"></el-input>
					</div>
					<div class="item">
						<div class="label bit">手机号：</div>
						<el-input type="number" v-model="userinfo.phone" placeholder="请输入手机号"></el-input>
					</div>
					<div class="item">
						<div class="label" :class="$route.query.id ? '' : 'bit'">密码：</div>
						<el-input type="password" v-model="userinfo.password" placeholder="请输入密码"></el-input>
					</div>
					<div class="item">
						<div class="label bit">头像：</div>
						<el-upload class="avatar-uploader" action="url" :show-file-list="false" ref="upload"
							:on-change="uploadChange" :headers="uploadHeaders" :auto-upload="false"
							:http-request="uploadFile">
							<img v-if="userinfo.url" :src="userinfo.url" class="avatar" />
							<img v-else-if="userinfo.user_avatar" :src="$store.state.imaUrl + userinfo.user_avatar"
								class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<div class="item">
						<el-button type="primary" @click="submitUpload">提交</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue";
	export default {
		components: {
			leftList,
			topHeader,
		},
		data() {
			return {
				title: '添加用户',
				uploadHeaders: {
					"Remember-Token": localStorage.getItem("rememberToken"),
				},
				isCollapse: false,
				options: [],
				userinfo: {
					corporateName: "",
					department: "",
					phone: "",
					password: "",
					url: "",
					user_avatar: '',
					files: '',
					nickname:''
				},
				formDate: ''
			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑用户'
				this.getInfo();
			}
			this.getCompanylist();
		},
		methods: {
			getInfo() {
				this.$get('front_user/get_details', {
					user_id: this.$route.query.id
				}).then(res => {
					console.log(res);
					this.userinfo.corporateName = res.data.data.company_id;
					this.userinfo.department = res.data.data.department;
					this.userinfo.phone = res.data.data.phone;
					this.userinfo.nickname = res.data.data.nickname;
					this.userinfo.user_avatar = res.data.data.avatarUrl;
				})
			},
			getCompanylist() {
				this.$get('company/get_list', {}).then(res => {
					this.options = res.data.data.data;
				})
			},
			submitUpload() {
				let _this = this;
				if (this.userinfo.corporateName == "") {
					this.$notify.error({
						title: "提示",
						message: "请选择公司！",
					});
					return false;
				}
				if (this.userinfo.nickname == "") {
					this.$notify.error({
						title: "提示",
						message: "昵称不可为空！",
					});
					return false;
				}
				if (this.userinfo.department == "") {
					this.$notify.error({
						title: "提示",
						message: "请填写所属部门！",
					});
					return false;
				}
				if (this.userinfo.phone == "") { // && !this.$route.query.id
					this.$notify.error({
						title: "提示",
						message: "手机号不可为空！",
					});
					return false;
				}
				if (!(/^[1][3-9][0-9]{9}$/.test(this.userinfo.phone))) {
					this.$notify.error({
						title: '提示',
						message: "手机号格式有误！",
					})
					return;
				}
				if (this.userinfo.password == "" && !this.$route.query.id) {
					this.$notify.error({
						title: "提示",
						message: "密码不可为空！",
					});
					return false;
				}
				if (this.userinfo.url == '' && this.userinfo.user_avatar == '') {
					this.$notify.error({
						title: "提示",
						message: "请上传头像！",
					});
					return false;
				}
				this.formDate = new FormData();
				_this.$refs.upload.submit();
				let config = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				this.formDate.append("company_id", this.userinfo.corporateName);
				this.formDate.append("department", this.userinfo.department);
				this.formDate.append("phone", this.userinfo.phone);
				this.formDate.append("password", this.userinfo.password);
				this.formDate.append("nickname", this.userinfo.nickname);
				if (this.$route.query.id) {
					this.formDate.append("id", this.$route.query.id);
					this.$axios
						.post(this.$store.state.httpUrl + "upd/front_user", this.formDate, config)
						.then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/user");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				} else {
					this.$axios
						.post(this.$store.state.httpUrl + "add/front_user", this.formDate, config)
						.then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/user");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				}
			},
			uploadFile(file) {
				this.formDate.append("avatarUrl", file.file);
				// console.log(this.formDate);
			},
			uploadChange(file, fileList) {
				console.log(file, fileList);
				this.userinfo.url = URL.createObjectURL(file.raw);
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/user") {
					this.$router.push("/user");
				} else if (index == 1 && this.$route.path != "/userRole") {
					this.$router.push("/userRole");
				} else if (index == 2 && this.$route.path != "/balance") {
					this.$router.push("/balance");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss">
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar-uploader {
		display: inline-block;
		vertical-align: top;
	}

	.avatar {
		width: 178px;
		height: 178px;
		// display: block;
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;

			.searchBtn {
				margin-left: 40px;
				padding: 12px 55px;
			}

			.el-input {
				width: 300px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.add-from {
			.item {
				margin-top: 40px;

				.el-button {
					width: 130px;
					margin-left: 110px;
				}

				.label {
					width: 120px;
					display: inline-block;
					position: relative;
					padding-left: 13px;
					box-sizing: border-box;
				}

				.el-input {
					width: 230px;
				}

				.bit {
					&::after {
						content: "*";
						position: absolute;
						left: 0px;
						top: -3px;
						color: red;
					}
				}
			}
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}
</style>
